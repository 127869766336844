/*Package Imports*/
import React, { useState, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/*App Imports*/
import { locationsActions } from "../../../data/redux/store/locations";
import { useGetMIMOErrorsCountQuery, useGetOpenTillCountQuery, useGetThresholdCountQuery } from "../../../data/redux/store/backofficeApi";
import DashboardData from "./dashboardData";

//Retrieve current member's organization data
const LocationsData = (props) => {
  const dispatch = useDispatch(); //organization state: sets current organization values based on current member org ID
  const currentMemberID = useSelector((state) => state.member.memberID);
  const currentMemberRole = useSelector((state) => state.member.role);
  const currentMemberDismissedList = useSelector((state) => state.member.dismissedList);
  const locations = useSelector((state) => state.locations.locations);
  const [isAllLoaded, setIsAllLoaded] = useState(false);
  const [copyMemberDismissed, setCopyMemberDismissed] = useState(
    currentMemberDismissedList !== undefined
      ? currentMemberDismissedList
          .filter((element) => element.LocationID === props.location.LocationID)
          .map((element) => element.ErrorLogID)
          .join(",")
      : "0"
  );
  const locationid = props.location.LocationID;

  const { data: openTillsCount, error: errorGetOpenTillsCount, isSuccess: successOpenTills } = useGetOpenTillCountQuery({ locationId: locationid });
  const { data: thresholdAlertsCount, error: errorGetThresholdAlertsCount, isSuccess: successThresholds } = useGetThresholdCountQuery({ locationId: locationid, min: 10, max: 90 });
  const {
    data: mimoErrorsCount,
    error: errorGetMimoErrorsCount,
    isSuccess: successMimoErrors,
    } = useGetThresholdCountQuery({ locationId: locationid, min: 10, max: 90 });
    // i hate everything - 2022-11-03
  //} = useGetMIMOErrorsCountQuery({ locationId: locationid, memberId: currentMemberID, memberDismissedListString: copyMemberDismissed.length > 0 ? copyMemberDismissed : "0" }, { skip: copyMemberDismissed === "0" });

  const getOrgData = useCallback(() => {
    if (locations.find((element) => element.LocationID === props.location.LocationID).OpenTillAlertsCount === -1 && successOpenTills) {
      dispatch(locationsActions.setValue({ field: "addData", type: "openTillCount", LocationID: props.location.LocationID, value: openTillsCount }));
    }
    if (locations.find((element) => element.LocationID === props.location.LocationID).ThresholdAlertsCount === -1 && successThresholds) {
      dispatch(locationsActions.setValue({ field: "addData", type: "thresholdCount", LocationID: props.location.LocationID, value: thresholdAlertsCount }));
    }
    if (locations.find((element) => element.LocationID === props.location.LocationID).MIMOErrorsCount === -1 && successMimoErrors) {
      dispatch(locationsActions.setValue({ field: "addData", type: "mimoErrorsCount", LocationID: props.location.LocationID, value: mimoErrorsCount }));
    }

    if (locations.every((element) => element.OpenTillAlertsCount > -1 && element.ThresholdAlertsCount > -1 && element.MIMOErrorsCount > -1)) {
      setIsAllLoaded(true);
    }
  }, [openTillsCount, dispatch, props.location.LocationID, successOpenTills, locations, mimoErrorsCount, thresholdAlertsCount, successThresholds, successMimoErrors]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getOrgData();
    }

    return () => (mounted = false);
  }, [getOrgData]);

  return <div>{errorGetOpenTillsCount !== undefined || errorGetMimoErrorsCount !== undefined || errorGetThresholdAlertsCount !== undefined ? <Redirect to="/auth/500" /> : isAllLoaded ? <DashboardData key={props.location.LocationID} LocationID={props.location.LocationID} /> : ""}</div>;
};

export default LocationsData;
