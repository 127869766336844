import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {};

const authSlice = createSlice({
  name: "authentication",
  initialState: initialAuthState,
  reducers: {
    login(state) {
      state.isAuthenticated = true;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.email = "";
      state.token = "";
      state.memberSessionID = 0;
      state.loadingData = true;
    },
    setToken(state, action) {
      state.token = action.payload;
      window.sessionStorage.setItem("mimo_api_token", action.payload);
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setMemberSessionID(state, action) {
      state.memberSessionID = action.payload;
    },
    setLoadData(state, action) {
      state.loadingData = action.payload;
    },
    PURGE(state) {
      return {};
    },
    REHYDRATE(state) {
      return {
        isAuthenticated: false,
        token: "",
        email: "",
        memberSessionID: 0,
        loadingData: true,
      };
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
