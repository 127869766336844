/*Package Imports*/
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux"; /*BackO_324 */
/*App Imports*/
import { authActions } from "../../data/redux/store/auth";
import { memberActions } from "../../data/redux/store/member";
import { organizationActions } from "../../data/redux/store/organization";
import { serviceActions } from "../../data/redux/store/service";

const SessionClosed = () => {
  const dispatch = useDispatch();
  dispatch(authActions.PURGE());
  dispatch(authActions.logout());
  dispatch(memberActions.resetMember());
  dispatch(organizationActions.resetOrganization());
  dispatch(serviceActions.resetService());

  const currentColorMode = useSelector ((state) => state.theme.currentColorMode) /*BackO_324 */

  return (
    <div className="text-center" style={{textTransform: "uppercase", color: currentColorMode.color}} /*BackO_324 */ >
      <h1 className="display-1 font-weight-bold" /*BackO_324 */ > Session has been closed.</h1>
      <br/>
      <p className="h2 font-weight-normal mt-3 mb-4">Your session has been closed because you signed in on another device. If this is a mistake, please contact your admin or MIMO support team.</p>
      <br/>
      <Link to="/">
        <Button color="btn btn-primary" size="lg"/*BackO_324 */>
          RETURN TO SIGN IN
        </Button>
      </Link>
    </div>
  );
};

export default SessionClosed;
