import { createSlice } from "@reduxjs/toolkit";

const registerSlice = createSlice({
  name: "register",
  initialState : {
    registerList: [],
    registerID: 0,
    name: "",
    enabled: false,
  },
  reducers: {
    getRegisterList: (state, {payload}) => {
      state.registerList = payload;
    },
    
  },
});

export const {getRegisterList} = registerSlice.actions;

export default registerSlice.reducer;
