import { createSlice } from "@reduxjs/toolkit";

const initialLocationsState = {};

const locationsSlice = createSlice({
  name: "locations",
  initialState: initialLocationsState,
  reducers: {
    setValue(state, action) {
      switch (action.payload.field) {
        case "addMostRecentlyClosedDate":
          state.mostRecentlyClosedBusinessDate = action.payload.value;
          break;
        case "addLocation":
          let tempArray = [...state.locations];
          const locIndex = tempArray.length;
          const payload = { ...action.payload.value, index: locIndex };
          if (tempArray.filter((element) => element.LocationID === payload.LocationID).length < 1) {
            tempArray.push(payload);
          }
          state.locations = tempArray;
          break;
        case "addData":
          let dataTempArray = [...state.locations];
          const dataPayload = action.payload;

          const index = dataTempArray.findIndex((element) => element.LocationID === dataPayload.LocationID);
          if (index >= 0) {
            let newLocation = dataTempArray.find((element) => element.LocationID === dataPayload.LocationID);
            if (dataPayload.type === "grandTotals") {
              newLocation.DBGrandTotals.push(dataPayload.value);
            }
            if (dataPayload.type === "cassette") {
              newLocation.DBCassetteHopperCount.push(dataPayload.value);
            }
            if (dataPayload.type === "averageNote") {
              newLocation.DBAverageCount.push(dataPayload.value);
            }
            if (dataPayload.type === "dailyTotals") {
              newLocation.DBDailyTotals.push(dataPayload.value);
            }
            if (dataPayload.type === "vaultTransactions") {
              newLocation.DBVaultTransactions.push(dataPayload.value);
            }
            if (dataPayload.type === "openTillCount") {
              newLocation.OpenTillAlertsCount = dataPayload.value;
            }
            if (dataPayload.type === "thresholdCount") {
              newLocation.ThresholdAlertsCount = dataPayload.value;
            }
            if (dataPayload.type === "mimoErrorsCount") {
              newLocation.MIMOErrorsCount = dataPayload.value;
            }
            dataTempArray.splice(index, 1, newLocation);
            state.locations = dataTempArray;
          }

          break;
        default:
          break;
      }
    },
    resetLocations(state) {
      state.locations = [];
    },
    PURGE(state) {
      // since users logged in after previous users add the previous users' locations, this seems like it's not working
      //return {};
      console.log("purging locations...");
      return [];

    },
    REHYDRATE(state) {
      return {
        locations: [],
      };
    },
  },
});

export const locationsActions = locationsSlice.actions;

export default locationsSlice.reducer;
