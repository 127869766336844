import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux"; //BackO_324

const Footer = () => {
  const currentColorMode = useSelector((state) => state.theme.currentColorMode); //BackO_324
  return (
    <footer className="footer">
      <Container fluid>
        {/* BackO_324 white-font was used in className, switch styling to use currentColorMode so the font can display correct color with the correct backgroundColor*/}
        <Row className="font-weight-bold" style={{ color: currentColorMode.color }}>
          <Col xs={8} className="text-left">
            {/* <ul className="list-inline">
              <li className="list-inline-item">
                <Link to="dashboard" className="mr-1" style={{ color: currentColorMode.color }}>
                  Support
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="dashboard" className="mr-1" style={{ color: currentColorMode.color }}>
                  Privacy
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="dashboard" className="mr-1" style={{ color: currentColorMode.color }}>
                  Terms of Service
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="dashboard" className="mr-1" style={{ color: currentColorMode.color }}>
                  Contact
                </Link>
              </li>
            </ul> */}
          </Col>
          <Col xs={4} className="text-right">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} -{" "}
              <Link to="dashboard" className="mr-1" style={{ color: currentColorMode.color }} >
                MIMO LLC
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
