import { createSlice } from "@reduxjs/toolkit";

const transactionSlice = createSlice({
  name: "transaction",
  initialState : {
    transactionList: [],
    tokenIdsList: [],
    tokenTypeIdsList: [],
    transactionID: 0,
    name: "",
    typeId: 0,
    vault: false,
    isPayout: false,
    payoutCategoryId: 0,
    bounding: false,
    enabled: false,
  },
  reducers: {
    getTransactionList: (state, {payload}) => {
      state.transactionList = payload;
    },
    getTokenIdsList: (state, {payload}) => {
      state.tokenIdsList = payload;
    },
    getTokenTypeIdsList: (state, {payload}) => {
      state.tokenTypeIdsList = payload;
    },
    newTransaction: (state, {payload}) => {
      state.transactionList.push({
        transactionID: payload.transactionID,
        name: payload.name,
        typeId: payload.typeId,
        vault: payload.vault,
        isPayout: payload.isPayout,
        payoutCategoryId: payload.payoutCategoryId,
        bounding: payload.bounding,
        enabled: payload.enabled,
      }); 
    },
    updatedTransaction: (state, {payload}) => {
      const transaction = state.transactionList
      .find((transaction) => transaction.transactionID === payload.transactionID);
        transaction.name = payload.name;
        transaction.bounding = payload.bounding;
      
      const updatedTokenTypes = payload.tokenTypeIdsList.map(tt => {
        return {
          transactionID: payload.transactionID,
          tokenTypeId: tt,
        }
      }) 
      state.tokenTypeIdsList = state.tokenTypeIdsList.filter(token => token.transactionID !== payload.transactionID);
      updatedTokenTypes.forEach(token => {
        return state.tokenTypeIdsList.push({
          transactionID: token.transactionID,
          tokenTypeId: token.tokenTypeId,
        })
      })
      
      const updatedTokenIds = payload.tokenIdsList.map(t => {
        return {
          transactionID: payload.transactionID,
          tokenId: t.tokenId,
          count: t.count,
        }
      })
      state.tokenIdsList = state.tokenIdsList.filter(token => token.transactionID !== payload.transactionID);
      updatedTokenIds.forEach(token => {
        return state.tokenIdsList.push({
          transactionID: token.transactionID,
          tokenId: token.tokenId,
          count: token.count
        })
      }); 
    },
    disableTransaction: (state, {payload}) => {
      const transaction = state.transactionList
      .find((transaction) => transaction.transactionID === payload.transactionID);
      transaction.enabled = false;
      state.transactionList = state.transactionList
      .filter((transaction) => transaction.transactionID !== payload.transactionID)
    },
    enableTransaction: (state, {payload}) => {
      const transaction = state.transactionList
      .find((transaction) => transaction.transactionID === payload.transactionID);
      transaction.enabled = true;
    },
  },
});

export const {getTransactionList, getTokenIdsList, getTokenTypeIdsList, newTransaction, updatedTransaction, 
    disableTransaction, enableTransaction} = transactionSlice.actions;

export default transactionSlice.reducer;
