/*this location slice is for MIMO Core, extract the locID for departments, users and so on.*/
import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
  name: "location",
  initialState : {
    locationID: 0,
  },
  reducers: {
    getLocationID: (state, {payload}) => {
      state.locationID = payload;
    },
  },
});

export const {getLocationID} = locationSlice.actions;

export default locationSlice.reducer;
