/*Package Imports*/
import React, { useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
/*App Imports*/
import { organizationActions } from "../../../data/redux/store/organization";
import { locationsActions } from "../../../data/redux/store/locations";
import { useGetOrganizationByOrganizationIDQuery, useGetAllLocationsQuery, useGetAllRolesQuery, useGetAllTimeZonesQuery, useGetAllMembersQuery } from "../../../data/redux/store/backofficeApi";
import LocationsData from "./locationsData";
import ServiceData from "./serviceData";

//Retrieve current member's organization data
const OrganizationData = () => {
  const dispatch = useDispatch(); //organization state: sets current organization values based on current member org ID
  const currentMemberOrganizationID = useSelector((state) => state.member.organizationID);
  const currentLocations = useSelector((state) => state.locations.locations);
  const currentMemberRole = useSelector((state) => state.member.role);
  //endpoint api/organization?organizationID={organizationID}
  const { data: organization, error: errorGetOrganization, isSuccess: gotOrg } = useGetOrganizationByOrganizationIDQuery(currentMemberOrganizationID, { skip: currentMemberOrganizationID === "" });
  //endpoint api/location
  const { data: locations, error: errorGetLocations, isSuccess: gotLoc } = useGetAllLocationsQuery(currentMemberOrganizationID, { skip: currentMemberOrganizationID === "" });
  //endpoint api/member
  const { data: members, error: errorGetMembers, isSuccess: gotMembers } = useGetAllMembersQuery({}, { skip: currentMemberOrganizationID === "" });
  //endpoint api/role
  const { data: roles, error: errorGetRoles, isSuccess: gotRoles } = useGetAllRolesQuery();
  //endpoint api/timezone
  const { data: timezones, error: errorGetTimeZones, isSuccess: gotTimeZones } = useGetAllTimeZonesQuery();

  dispatch(organizationActions.setValue({ field: "organizationID", value: currentMemberOrganizationID }));

  const getOrgData = useCallback(() => {
    if (organization !== undefined) {
      dispatch(
        organizationActions.setOrganization({
          organizationID: organization.OrganizationID,

          organizationName: organization.OrganizationName,

          passwordsExpireIn: organization.PasswordsExpireIn,

          totalLicenses: organization.TotalLicenses,

          totalInUse: 0,

          licenseStatus: organization.LicenseStatus,

          locations: [],

          members: [],

          thresholdAlertsList: [],

          opentillAlertsList: [],

          mimoErrorsList: [],

          exposureAlertsList: [],

          roles: [],

          timezones: [],
        })
      );
    } else if (errorGetOrganization !== undefined) {
      console.log(errorGetOrganization);
    }

    if (locations != null) {
      dispatch(organizationActions.setValue({ field: "locations", value: locations }));
      locations.forEach((element) => {
        const newLocation = {
          LocationID: element.LocationID,
          LocationName: element.LocationName,
          MIMOErrorsCount: -1,
          ThresholdAlertsCount: -1,
          OpenTillAlertsCount: -1,
          MIMOErrors: [],
          ExposureAlerts: [],
          ThresholdAlerts: [],
          OpenTillAlerts: [],
          DBGrandTotals: [],
          DBCassetteHopperCount:[],
          DBDailyTotals: [],
          DBAverageCount: [],
          DBVaultTransactions: [],
        };
        dispatch(locationsActions.setValue({ field: "addLocation", value: newLocation }));
      });
    } else if (errorGetLocations !== undefined) {
      console.log(errorGetLocations);
    }

    if (members !== undefined) {
      let totalCountActive = 0;

      members.forEach((element) => {
        if (element.enabled === "Enabled") {
          totalCountActive++;
        }
      });
      dispatch(organizationActions.setValue({ field: "members", value: members }));
      dispatch(organizationActions.setValue({ field: "totalInUse", value: totalCountActive }));
    } else if (errorGetMembers !== undefined) {
      console.log(errorGetMembers);
    }

    if (roles != null) {
      dispatch(organizationActions.setValue({ field: "roles", value: roles }));
    } else if (errorGetRoles !== undefined) {
      console.log(errorGetRoles);
    }

    if (timezones != null) {
      dispatch(organizationActions.setValue({ field: "timezones", value: timezones }));
    } else if (errorGetTimeZones !== undefined) {
      console.log(errorGetTimeZones);
    }
  }, [dispatch, errorGetLocations, errorGetMembers, errorGetOrganization, errorGetRoles, locations, members, organization, roles, errorGetTimeZones, timezones]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getOrgData();
    }
    return () => (mounted = false);
  }, [getOrgData]);

  return (
    <div>
      {errorGetOrganization !== undefined || errorGetLocations !== undefined || errorGetMembers !== undefined ? (
        <Redirect to="/auth/500" />
      ) : (currentMemberRole === "Service" || currentMemberRole === "Billing") && organization !== undefined && locations !== undefined && members !== undefined && roles !== undefined ? (
        <ServiceData />
      ) : gotOrg && gotLoc && gotMembers && gotRoles && gotTimeZones ? (
        currentLocations.map((location, index) => {
          return <LocationsData key={location.LocationID} locationsList={currentLocations} location={location} />;
        })
      ) : (
        ""
      )}
    </div>
  );
};

export default OrganizationData;
