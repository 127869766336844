import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux"; /*BackO_324 */
import bgPattern from "../assets/img/photos/bg-pattern.png";

/*BackO_324 */
// Added a hook so currentColorMode can be used within wrapper.
const Wrapper = ({ className, children }) =>  {
    const currentColorMode = useSelector((state) => state.theme.currentColorMode);
  return(
  <div className={classNames("wrapper", className)} style={{ backgroundColor: currentColorMode.backgroundColor }}>
    {children}
  </div>
);
}

export default Wrapper;
