/*Package Imports*/
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux"; /*BackO_324 */
/*App Imports*/
import { authActions } from "../../data/redux/store/auth";
import { memberActions } from "../../data/redux/store/member";
import { organizationActions } from "../../data/redux/store/organization";
import { serviceActions } from "../../data/redux/store/service";

const Page404 = () => {
  const dispatch = useDispatch();

  dispatch(memberActions.PURGE());
  dispatch(organizationActions.PURGE());
  dispatch(serviceActions.PURGE());
  dispatch(authActions.PURGE());

  const currentColorMode = useSelector((state) => state.theme.currentColorMode); /*BackO_324 */

  return (
    <div className="text-center" style={{color: currentColorMode.color, textTransform:"uppercase"}} /*BackO_324 */> 
      <h1 className="display-1 font-weight-bold ">401</h1>
      <p className="h1">Unauthorized</p>
      <p className="h2 font-weight-normal mt-3 mb-4"> You do not have access to the page you are referencing. </p>
      <Link to="/">
        <Button color="primary" size="lg">
          Return to website
        </Button>
      </Link>
    </div>
  );
};

export default Page404;
