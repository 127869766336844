import { createSlice } from "@reduxjs/toolkit";

const initialOrganizationState = {};

const organizationSlice = createSlice({
  name: "organization",
  initialState: initialOrganizationState,
  reducers: {
    setValue(state, action) {
      switch (action.payload.field) {
        case "organizationID":
          state.organizationID = action.payload.value;
          break;
        case "organizationName":
          state.organizationName = action.payload.value;
          break;
        case "passwordsExpireIn":
          state.passwordsExpireIn = action.payload.value;
          break;
        case "totalLicenses":
          state.totalLicenses = action.payload.value;
          break;
        case "totalInUse":
          state.totalInUse = action.payload.value;
          break;
        case "licenseStatus":
          state.licenseStatus = action.payload.value;
          break;
        case "locations":
          state.locations = action.payload.value;
          break;
        case "members":
          state.members = action.payload.value;
          break;
        case "thresholdAlerts":
          state.alerts.thresholdAlertsList = action.payload.value;
          break;
        case "opentillAlerts":
          state.alerts.opentillAlertsList = action.payload.value;
          break;
        case "mimoErrors":
          state.alerts.mimoErrorsList = action.payload.value;
          break;
        case "exposureAlerts":
          state.alerts.exposureAlertsList = action.payload.value;
          break;
        case "roles":
          state.roles = action.payload.value;
          break;
        case "timezones":
          state.timezones = action.payload.value;
          break;
        case "changedMemberData":
          state.changedMemberData = action.payload.value;
          break;
        case "changedLocationData":
          state.changedLocationData = action.payload.value;
          break;
        default:
          break;
      }
    },
    setOrganization(state, action) {
      state.organizationID = action.payload.organizationID;

      state.organizationName = action.payload.organizationName;

      state.passwordsExpireIn = action.payload.passwordsExpireIn;

      state.totalLicenses = action.payload.totalLicenses;

      state.totalInUse = action.payload.totalInUse;

      state.licenseStatus = action.payload.licenseStatus;

      state.locations = action.payload.locations;

      state.members = action.payload.members;

      state.alerts.thresholdAlertsList = action.payload.thresholdAlertsList;

      state.alerts.opentillAlertsList = action.payload.opentillAlertsList;

      state.alerts.mimoErrorsList = action.payload.mimoErrorsList;

      state.alerts.exposureAlertsList = action.payload.exposureAlertsList;

      state.roles = action.payload.roles;

      state.timezones = action.payload.timezones;
    },
    resetOrganization(state) {

      state.organizationID = 0;
      state.organizationName = "";
      state.passwordsExpireIn = 0;
      state.totalLicenses = -1;
      state.totaInUse = -1;
      state.licenseStatus = 0;
      state.locations = [
        {
          LocationId: 0,
          apiID: 0,
          LocationName: "",
          OrganizationId: 0,
          TimeZoneId: 0,
          TimeZoneName: "",
          LocationDatabaseName: "",
          APIUser: "",
          APIPassword: "",
          Enabled: 0,
        },
      ];
      state.members = [
        {
          memberID: 0,
          firstName: "",
          lastName: "",
          role: "",
          organization: "",
          location: "",
          department: "",
          email: "",
          phone: "",
          enabled: 0,
          organizationID: 0,
          className: "",
          permissions: [],
        },
      ];
      state.alerts.thresholdAlertsList = [];
      state.alerts.opentillAlertsList = [];
      state.alerts.mimoErrorsList = [];
      state.alerts.exposureAlertsList = [];
      state.roles = [{ roleID: 0, roleName: "" }];
      state.timezones = [{ timeZoneID: 0, timeZoneName: "" }];
      state.changedMemberData = false;
      state.changedLocationData = false;
    },
    PURGE(state) {
      return {};
    },
    REHYDRATE(state) {

      return {
        organizationID: 0,
        organizationName: "",
        passwordsExpireIn: 0,
        totalLicenses: -1,
        totalInUse: -1,
        licenseStatus: 0,
        locations: [
          {
            LocationId: 0,
            apiID: 0,
            LocationName: "",
            OrganizationId: 0,
            TimeZoneId: 0,
            TimeZoneName: "",
            LocationDatabaseName: "",
            APIUser: "",
            APIPassword: "",
            Enabled: 0,
          },
        ],
        members: [
          {
            memberID: 0,
            firstName: "",
            lastName: "",
            role: "",
            organization: "",
            location: "",
            department: "",
            email: "",
            phone: "",
            enabled: 0,
            organizationID: 0,
            permissions: [],
          },
        ],
        alerts: {
          thresholdAlertsList: [],
          opentillAlertsList: [],
          mimoErrorsList: [],
          exposureAlertsList: [],
        },
        roles: [{ roleID: 0, roleName: "" }],
        timezones: [{ timeZoneID: 0, timeZoneName: "" }],
        changedMemberData: false,
        changedLocationData: false,
      };
    },
  },
});

export const organizationActions = organizationSlice.actions;

export default organizationSlice.reducer;
