// import React from "react";
// import classNames from "classnames";

// const Main = ({ className, children }) => <div className={classNames("main", className)}>{children}</div>;

// export default Main;

import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";


//BackO_324
// Main color for all pages will need to be changed here
//change const main so the useSelector hook can reach the correct data from 
//currentColorMode and provide the correct background color on light and dark mode toggle 
const Main = ({ className, children }) => {
    const currentColorMode = useSelector((state) => state.theme.currentColorMode);
 return(
<div className={classNames("main", className)} style={{ backgroundColor: currentColorMode.backgroundColor }}>{children}</div>
);
};

export default Main;
