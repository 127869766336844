import { createSlice } from "@reduxjs/toolkit";

const initialSidebarState = {
  isOpen: true,
  isOnRight: false,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: initialSidebarState,
  reducers: {
    toggle(state) {
      state.isOpen = !state.isOpen;
    },
    show(state) {
      state.isOpen = true;
    },
    hide(state) {
      state.isOpen = false;
    },
    enableOnRight(state) {
      state.isOnRight = true;
    },
    disableOnRight(state) {
      state.isOnRight = false;
    },
  },
});

export const sidebarActions = sidebarSlice.actions;

export default sidebarSlice.reducer;
